import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { WellextKitModule } from '@wellext/kit';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpaserverService } from './spaserver.service';
import { ApiModule } from '@wellext/spa-api';
import { HttpClientModule } from '@angular/common/http';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { environment } from '../environments/environment';
import { CheckOutPaymentModule } from './views/check-out-payment/check-out-payment.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    WellextKitModule,
    ApiModule,
    CheckOutPaymentModule,
  ],
  providers: [
    SpaserverService,
    {
      provide: InjectableRxStompConfig,
      useValue: <InjectableRxStompConfig>{
        brokerURL: environment.brokerUrl,
        connectHeaders: {
          login: 'admin',
          passcode: 'guest',
        },
        heartbeatIncoming: 0,
        heartbeatOutgoing: 1000 * 5,
        reconnectDelay: 500,
        // debug: (msg: string): void => {
        //   console.log(new Date(), msg);
        // },
      },
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
