import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AdminService,
  CateringService,
  CheckinCheckoutService,
  RoomService,
} from '@wellext/spa-api';
import { environment } from '../environments/environment';
import { firstValueFrom, timer } from 'rxjs';

@Component({
  selector: 'wlx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  protected init = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private cateringService: CateringService,
    private roomService: RoomService,
    private adminService: AdminService,
    private cioService: CheckinCheckoutService
  ) {}

  async ngOnInit() {
    await firstValueFrom(timer(200));
    const authKey =
      this.activatedRoute.snapshot.queryParamMap.get('authKey') || localStorage.getItem('authKey');

    const authKeyNotSpecified = authKey === '' || authKey === undefined || authKey === null;

    if (authKeyNotSpecified) {
      throw new Error('missig authKey query param, cannot init');
    }

    localStorage.setItem('authKey', authKey);
    this.setAuthKey(authKey);
    this.init = true;
  }

  private setAuthKey(authKey: string) {
    this.roomService.configuration.basePath = environment.apiBasePath;
    this.cioService.configuration.basePath = environment.apiBasePath;
    this.adminService.configuration.basePath = environment.apiBasePath;
    this.cateringService.configuration.basePath = environment.apiBasePath;

    this.roomService.defaultHeaders = this.roomService.defaultHeaders.set('authorization', authKey);
    this.cioService.defaultHeaders = this.cioService.defaultHeaders.set('authorization', authKey);
    this.adminService.defaultHeaders = this.adminService.defaultHeaders.set(
      'authorization',
      authKey
    );
    this.cateringService.defaultHeaders = this.cateringService.defaultHeaders.set(
      'authorization',
      authKey
    );
  }
}
